<template>
  <div class="bg-gray-900 p-2 text-white">
    <VContainer>
      <div class="flex justify-end w-full gap-4 sm:text-sm items-center">
        <CountrySelector />
        <span class="h-6 w-px bg-gray-700"></span>
        <LocaleSelector />
        <span class="h-6 w-px bg-gray-700"></span>
        <CurrencySelector />
      </div>
    </VContainer>
  </div>
</template>
