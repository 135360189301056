<script setup lang="ts">
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useSchemaOrg, defineWebPage, defineWebSite } from '@unhead/schema-org/vue';

const config = useRuntimeConfig();
const { locale } = useI18n();
const { execute: fetchBootstrap } = useBootstrap()
const { execute: fetchCart } = useCart();
const { execute: fetchCustomer } = useAuth();

await Promise.all([fetchBootstrap(), fetchCart(), fetchCustomer()]);

watch(locale, fetchBootstrap);

useSchemaOrg([
  defineWebSite({
    name: config.public.app_name,
    url: config.public.app_url,
  }),
  defineWebPage(),
]);
</script>

<template>
  <NuxtLoadingIndicator />
  <ClientOnly>
    <CookieOptions />
  </ClientOnly>
  <Notifications />
  <ClientOnly>
    <GeolocationBar />
  </ClientOnly>
  <AppBar />
  <AppNavbar />
  <BottomBar />
  <main>
    <slot />
  </main>
  <AppFooter />
  <VCartSidebar />
</template>
