<script setup>
const { locale, locales } = useI18n()
const switchLocalePath = useSwitchLocalePath()

const availableLocales = computed(() => {
  return locales.value
})

const currentLocale = computed(() => {
  return locale.value;
})

const changeLocale = (event) => {
  const newLocale = event.target.value
  if (newLocale) {
    navigateTo(switchLocalePath(newLocale))
  }
}
</script>

<template>
  <div class="group relative -ml-2 rounded-md border-transparent bg-gray-900 focus-within:ring-2 focus-within:ring-white">
    <select
      @change="changeLocale"
      v-model="currentLocale"
      class="flex focus items-center rounded border-transparent bg-gray-900 py-0.5 pl-2 text-sm font-medium text-white focus:border-transparent focus:outline-none focus:ring-0 group-hover:text-gray-100"
    >
      <option v-for="locale in availableLocales" :key="locale.code" :value="locale.code">
        {{ locale.label }}
      </option>
    </select>
  </div>
</template>
