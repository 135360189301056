<script lang="ts" setup>
import { LoaderCircle, Search } from 'lucide-vue-next';
import { onClickOutside, useDebounceFn } from '@vueuse/core';
import VProductPrice from './VProductPrice.vue';
import { useSdk } from '~/sdk';

const router = useRouter();
const localePath = useLocalePath();
const sdk = useSdk();
const { locale } = useI18n();

const showResults = ref(false);
const selectedIndex = ref(-1);
const panel = ref<any>(null);
const wrapper = ref<any>(null);
const results = ref<any>({ products: [] });
const searchTerm = ref<string>('');
const loading = ref<boolean>(false);
const error = ref<any>('');
const hasResults = computed(() => results.value.products.length > 0);
const showDropdown = computed(() => showResults.value && searchTerm.value.length > 1);

// Fetch search results
const fetchResults = async () => {

  if (!searchTerm.value || searchTerm.value.length < 2) return;

  loading.value = true;

  try {
    const data = await sdk.commerce.search({ s: searchTerm.value, page: 1 });
    results.value = data ?? { products: [] };
  } catch (err) {
    error.value = (err as Error).message || 'Search failed';
  } finally {
    loading.value = false;
  }
};

// Debounce search
const search = useDebounceFn(fetchResults, 300);

// Keyboard navigation
const navigateResults = (direction: 'up' | 'down') => {
  if (!hasResults.value) return;
  selectedIndex.value = direction === 'up'
    ? Math.max(-1, selectedIndex.value - 1)
    : Math.min(results.value.products.length - 1, selectedIndex.value + 1);
};

// Close dropdown when clicking outside
const closeDropdown = () => {
  showResults.value = false;
  (document.activeElement as HTMLElement)?.blur();
};

// Handle enter key press
const handleSelection = () => {
  if (selectedIndex.value === -1) {
    router.push({ path: localePath('search'), query: { s: searchTerm.value } });
  } else {
    //@ts-ignore
    router.push(new URL(results.value.products[selectedIndex.value].link).pathname);
  }
  closeDropdown();
}

// Scroll to selected item
watch(selectedIndex, () => {
  if (!panel.value) return;
  const selectedItem = panel.value.querySelector('.selected');
  if (selectedItem) {
    panel.value.scrollTo({
      top: (selectedItem as HTMLElement).offsetTop - panel.value.offsetTop - panel.value.clientHeight / 2,
      behavior: 'smooth',
    });
  }
})

// Watch locale and search term
watch(locale, search);

// Close dropdown on click outside
onClickOutside(wrapper, () => {
  showResults.value = false;
});

// Close dropdown on route change
router.afterEach(() => {
  showResults.value = false;
});
</script>

<template>
  <div class="w-full relative">
    <div ref="wrapper" class="relative w-full max-w-xl">
      <div class="flex items-center bg-white px-2 rounded border" @click="showResults = true">
        <LoaderCircle v-if="loading" class="h-4 w-4 shrink-0 opacity-50" />
        <Search v-else />
        <input
          v-model="searchTerm"
          :placeholder="$t('Search')+'...'"
          class="w-full h-9 border-none max-w-xl focus:ring-0"
          @keydown.down.prevent="navigateResults('down')"
          @keydown.up.prevent="navigateResults('up')"
          @keydown.enter="handleSelection()"
          @focus="showResults = true"
          @change="selectedIndex = -1"
          @input="search"
        />
      </div>

      <div v-if="showDropdown" class="absolute top-10 z-20 bg-white rounded shadow inset-x-0 border">

        <template  v-if="loading">
          <div class="px-4 py-2 italic"> 
            {{ $t('Searching','Searching') }} ...
          </div>
          <div v-if="error" class="px-4 py-2 font-semibold text-red-500">
            {{ error }}
          </div>
        </template>
        <template v-else>
          <div v-if="!hasResults" class="px-4 py-2 font-semibold">
            No results found.
          </div>
          <div v-else>
            <div
              @click="[router.push(localePath(`/search?s=${searchTerm}&page=1`)), closeDropdown()]"
              class="px-4 py-2 cursor-pointer hover:bg-gray-100"
              :class="{ 'bg-gray-100': selectedIndex === -1 }"
            >
              {{ $t('Show all results') }} '<span class="font-semibold">{{ searchTerm }}</span>'
            </div>
            <div  ref="panel"  class="max-h-[480px] overflow-visible overflow-y-auto">
              <div
                v-for="(result, index) in results?.products"
                :key="result.id"
                class="px-4 py-2 border-b cursor-pointer hover:bg-gray-100"
                :class="{ 'bg-gray-100 selected': selectedIndex === index }"
              >
                <NuxtLink 
                  class="flex items-start gap-2" 
                  :to="result.link.replace(useRuntimeConfig().public.prestashop_url, '')" 
                  @click="closeDropdown"
                >
                  <img :src="result.cover.url" class="h-20">
                  <div class="flex flex-col">
                    <h3>{{ result.name }}</h3>
                    <div class="flex items-center justify-between">
                      <VProductPrice
                        :prices-data="{
                          specific_prices: result.specific_prices,
                          price: result.price,
                          regular_price: result.regular_price,
                          discount_percentage: result.discount_percentage,
                        }"
                        size="sm"
                        container-styles="py-2"
                      />
                      <!-- <div class="flex-col gap-2 left-2" v-if="result.cart_rules && result.cart_rules.length > 0">
                        <div v-for="rule in result.cart_rules" >
                          <VCartRule :rule="rule" />
                        </div>
                      </div> -->
                    </div>
                  </div>
                </NuxtLink>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
